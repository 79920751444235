import gql from 'graphql-tag';

export const GetDefaultSpaceQuery = gql`
	query GetDefaultSpaceQuery($promotedSpaceKey: String) @experimental {
		experimentalPromotedSpaces(
			promotedSpacesLimit: 1
			otherSpacesLimit: 1
			promotedSpaceKey: $promotedSpaceKey
		) {
			nodes {
				id
			}
		}
	}
`;
