import type { MutableRefObject } from 'react';
import { createContext } from 'react';

import type { NavigationTheme } from '@atlaskit/atlassian-navigation';

type AppNavigationContextValue = {
	isBranchDeploy: boolean;
	theme?: NavigationTheme;
	resetStickySearchRef?: MutableRefObject<() => void>;
	profileMenuButtonRef?: MutableRefObject<HTMLButtonElement | null>;
	primaryDropdownItemRef?: MutableRefObject<HTMLElement | null>;
	createContentPopoverButtonRef?: MutableRefObject<HTMLElement | null>;
};

export const AppNavigationContext = createContext<AppNavigationContextValue>({
	isBranchDeploy: false,
});

AppNavigationContext.displayName = 'AppNavigationContext';
