import gql from 'graphql-tag';

export const WhiteboardCreationPaywallContentToDisableQuery = gql`
	query WhiteboardCreationPaywallContentToDisableQuery {
		paywallContentToDisable(contentType: "whiteboard") {
			deactivationIdentifier
			link
			title
		}
	}
`;
