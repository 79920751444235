import { AccessStatus, useSessionData } from '@confluence/session-data';

const isAllowedToCreate = (accessStatus: AccessStatus | null): boolean =>
	accessStatus === AccessStatus.LICENSED_ADMIN_ACCESS ||
	accessStatus === AccessStatus.LICENSED_USE_ACCESS ||
	accessStatus === AccessStatus.EXTERNAL_COLLABORATOR_ACCESS;

export const useIsAllowedToCreateWhiteboard = (): boolean => {
	const { accessStatus } = useSessionData();

	return isAllowedToCreate(accessStatus);
};
