import gql from 'graphql-tag';

export const SpaceEnabledContentTypesQuery = gql`
	query SpaceEnabledContentTypesQuery($spaceKey: String) {
		space(key: $spaceKey) {
			id
			key
			spaceTypeSettings {
				enabledContentTypes {
					isBlogsEnabled
					isDatabasesEnabled
					isEmbedsEnabled
					isLivePagesEnabled
					isWhiteboardsEnabled
					isFoldersEnabled
				}
			}
		}
	}
`;
