import type { FC } from 'react';
import React, { useCallback, useEffect } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { SpotlightTarget, SpotlightTransition, Spotlight } from '@atlaskit/onboarding';

import { LoadableAfterPaint } from '@confluence/loadable';

import { GlobalCreateContentPopover } from './GlobalCreateContentPopover';

const i18n = defineMessages({
	gaSpotlightBody: {
		id: 'create-blank-fabric-page.global-create.ga-spotlight-body',
		defaultMessage: 'Create a whiteboard at any time using this button.',
		description: 'Text for a spotlight that is shown on the create button in the header',
	},
	gaSpotlightCloseButton: {
		id: 'create-blank-fabric-page.global-create.ga-spotlight-close',
		defaultMessage: 'Okay',
		description: 'Text for button that will close spotlight',
	},
	spotlightBody: {
		id: 'create-blank-fabric-page.global-create.spotlight-body',
		defaultMessage:
			'Create whiteboards from wherever you are in Confluence, whenever inspiration strikes.',
		description: 'Text for a spotlight that is shown on the create button in the header',
	},
	spotlightCloseButton: {
		id: 'create-blank-fabric-page.global-create.spotlight-close',
		defaultMessage: 'Got it',
		description: 'Text for button that will close spotlight',
	},
});

export interface GlobalCreateContentPopoverSpotlightProps {
	isGA?: boolean;
	spotlightEnabled: boolean;
	onSpotlightClose: () => void;
}

export const GlobalCreateContentPopoverSpotlight: FC<GlobalCreateContentPopoverSpotlightProps> = ({
	isGA,
	spotlightEnabled,
	onSpotlightClose,
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();

	const onClose = useCallback(() => {
		onSpotlightClose();
		if (isGA) {
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'clicked',
					actionSubject: 'button',
					actionSubjectId: 'closeWhiteboardGAOnboardingSpotlight',
					source: 'globalCreateSpotlight',
				},
			}).fire();
		}
	}, [createAnalyticsEvent, isGA, onSpotlightClose]);

	useEffect(() => {
		if (spotlightEnabled && isGA) {
			createAnalyticsEvent({
				type: 'sendScreenEvent',
				data: {
					name: 'globalCreateWBSpotlightScreen',
					attributes: { boardType: 'ga-onboarding' },
					source: 'globalNavigation',
				},
			}).fire();
		}
	}, [createAnalyticsEvent, isGA, spotlightEnabled]);

	return (
		<>
			<SpotlightTarget name="nav-create-whiteboard-onboarding">
				<GlobalCreateContentPopover />
			</SpotlightTarget>
			<SpotlightTransition>
				{spotlightEnabled && (
					<Spotlight
						dialogWidth={287}
						targetRadius={3}
						actions={[
							{
								onClick: onClose,
								text: formatMessage(isGA ? i18n.gaSpotlightCloseButton : i18n.spotlightCloseButton),
							},
						]}
						target="nav-create-whiteboard-onboarding"
						key="nav-create-whiteboard-onboarding"
					>
						<FormattedMessage {...(isGA ? i18n.gaSpotlightBody : i18n.spotlightBody)} />
					</Spotlight>
				)}
			</SpotlightTransition>
		</>
	);
};

export const GlobalCreateContentPopoverSpotlightLoader = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-GlobalCreateContentPopoverSpotlight" */ './GlobalCreateContentPopoverSpotlight'
			)
		).GlobalCreateContentPopoverSpotlight,
	_noPreloadWhenRenderingSPA: true,
}) as FC<GlobalCreateContentPopoverSpotlightProps>;
