import type { ApolloError } from 'apollo-client';

import { UNSAFE_noExposureExp } from '@confluence/feature-experiments';

import { useIsXflowUser } from '@confluence/onboarding-helpers/entry-points/hooks/useIsXflowUser';

enum Cohorts {
	ENROLLED = 'enrolled',
	NOT_ENROLLED = 'not-enrolled',
	CONTROL = 'control',
	EXPERIMENT = 'experiment',
}

type useIsEligibleForSurfaceImportType = {
	isEligibleForSurfaceImport: boolean;
	isSurfaceImportExperimentCohort: boolean;
	surfaceImportLoading: boolean;
	sitePersonalizationError: ApolloError | Error | undefined;
};

export const useIsEligibleForSurfaceImport = (): useIsEligibleForSurfaceImportType => {
	// If loading || error, isXflowUser is already false
	const [experiment] = UNSAFE_noExposureExp('confluence_surface_import');
	const cohort = experiment.get<Cohorts>('cohort', Cohorts.NOT_ENROLLED) as Cohorts;

	const { isXflowUser, isLoading, error } = useIsXflowUser({
		skip: cohort === Cohorts.NOT_ENROLLED,
	});

	return {
		isEligibleForSurfaceImport: isXflowUser && cohort !== Cohorts.NOT_ENROLLED,
		isSurfaceImportExperimentCohort: isXflowUser && cohort === Cohorts.EXPERIMENT,
		surfaceImportLoading: isLoading,
		sitePersonalizationError: error,
	};
};
