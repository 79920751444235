import { useCallback } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';

import { useSessionData } from '@confluence/session-data';
import { ConfluenceEdition } from '@confluence/change-edition/entry-points/ConfluenceEdition';
import { useIsAllowedToCreateWhiteboard } from '@confluence/content-types-utils';
import { useIsWhiteboardFeatureEnabled } from '@confluence/whiteboard-utils';

import { WhiteboardCreationPaywallContentToDisableQuery } from '../queries/WhiteboardCreationPaywallContentToDisableQuery.graphql';

type UsePreloadCreationMenuDataResponse = () => void;
export const usePreloadCreationMenuData = (): UsePreloadCreationMenuDataResponse => {
	const { edition } = useSessionData();
	const isPremium = edition === ConfluenceEdition.PREMIUM;

	const { isWhiteboardFeatureEnabled } = useIsWhiteboardFeatureEnabled();
	const isWhiteboardPaywallEnabled = isWhiteboardFeatureEnabled('whiteboardPaywall');
	const isAllowedToCreateWhiteboard = useIsAllowedToCreateWhiteboard();

	const [preloadWhiteboardPaywallData] = useLazyQuery(
		WhiteboardCreationPaywallContentToDisableQuery,
	);

	const shouldPreloadPaywallData = isAllowedToCreateWhiteboard && !isPremium;
	return useCallback(() => {
		if (shouldPreloadPaywallData && isWhiteboardPaywallEnabled) {
			preloadWhiteboardPaywallData();
		}
	}, [preloadWhiteboardPaywallData, shouldPreloadPaywallData, isWhiteboardPaywallEnabled]);
};
